import AuthService from "../Services/AuthService";
import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {css} from "glamor";
import utils from "../Utils/collections";

/**
 * Componente del calendario
 * @param {*} props datos del usuario que esta logeado
 * @returns la vista del calendario
 */

const Objetivos = (props) => {
    var MesNumVar = 0;
    var AnioNumVar = 0;
    let history = useHistory();
    const [currentUser] = useState(props.user);
    console.log('-----------> Current User is: '+currentUser.idu);
    const [goalsBD, setGoalsBD] = useState([]);
    const [datos, setDatos] = useState({
        prospectos: "0",
        experiencias: "0",
        ventas: "0",
        budgets: "0"
    });
    //const [setMonth] = useState(0);
    //const [year, setYear] = useState(0);
    const [editar, setEditar] = useState(false);
    const [mostrarBotones, setMostrarBotones] = useState(true);
    const [calendarioVorwerk, setCalendarioVorwerk] = useState([]);
    //const [CurrentFecha, setCurrentFecha] = useState([]);
    //const [CurrentMesNum, setCurrentMesNum] = useState(0);
    //const [CurrentAnioNum, setCurrentAnioNum] = useState(0);

    useEffect(() => {

        //Aqui vamos a consultar el servicio de calendarioVorwerk
        console.log("Consulta de calendario vorwerk Desde Inicio")
        //To Do Ver2: Set the Calendar Qerry From an unified function from the backend....
        let gqlClndVrwr = `{
            vorwerkcalendar{
                id
                calendario2022 {
                    nombreMes
                    fechaInicio
                    fechaFin
                }
                calendario2023 {
                    nombreMes
                    fechaInicio
                    fechaFin
                }
                calendario2024 {
                    nombreMes
                    fechaInicio
                    fechaFin
                }
                calendario2025 {
                    nombreMes
                    fechaInicio
                    fechaFin
                }
            }
        }`

        AuthService.consult(gqlClndVrwr)
        .then(result=>{
            let calendario = result.data.data.vorwerkcalendar


            //BNDDEV2025: Calendar debug:
            function prettyJSONv(obj) {
                let stringed = JSON.stringify(obj, null, 2);
                return stringed
            }
            console.log('BNDDEV2025: Calendardebug '+prettyJSONv(calendario));
            


            // Esta consulta debe ser en base al mes Vorwerk
            let fecha = new Date();
            //To Do Ver2: This part uses the old calendar dates by variables Must be removed.
            if(calendario && calendario.length>0){console.log('El valor de fecha fase 1 es: '+fecha)}
            let fechaVorwerk = utils.calendarioVorwerk[fecha.getMonth()][fecha.getMonth()][1].fin;
            let fechaVorwerkInicio = utils.calendarioVorwerk[fecha.getMonth()][fecha.getMonth()][0].inicio;
            let mesConsultar = fecha.getMonth()+1
            console.log('BNDDEV mesConsultar:'+mesConsultar);
            let fechaVorwerkActual = [{'inicio': ''}, {'fin': ''}]
            //To Do Ver2: END OF - This part uses the old calendar dates by variables Must be removed.
            if(calendario && calendario.length>0){
                console.log("Inicio.js Existe calendario 2022", calendario[0].calendario2022)
                console.log("Inicio.js Existe calendario 2023", calendario[0].calendario2023)

                //To Do Ver2: Add the mutator and Fecha global as export functions...
                function TimeZoneMutator(numOfHours, date) {
                    date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
                
                    return date;
                  }
                  function GetFechaGlobal(numOfHours, date = new Date()) {
                    date.setTime(date.getTime() - numOfHours * 60 * 60 * 1000);
                
                    return date;
                  }
                
                
                var ToMutateFin2022 = new Date (calendario[0].calendario2022[11].fechaFin)
                var ToMutateFin2023 = new Date (calendario[0].calendario2023[11].fechaFin)

                const Fin2022 = TimeZoneMutator(6,ToMutateFin2022);
                const Fin2023 = TimeZoneMutator(6,ToMutateFin2023);

                //To Do Ver2: Delete this console logs....
                console.log('El final de 2022 es: ' + Fin2022);
                console.log('El final de 2023 es: ' + Fin2023);

                //To Do Ver2: Current Date can be changed here for test in front.
                //To Do Release: Horas sumadas deben ser 0 para no ajustar fecha actual.
                var CurrentFecha = GetFechaGlobal(0);
                //setCurrentFecha(CurrentFecha)
                console.log('La fecha actual es: ' + CurrentFecha);

                var CurrentAnio = 2021;
                var CurrentMes = '';
                var MesID = 0;
                if(CurrentAnio < 2022){
                    console.log('Revisando si las fechas corresponden a 2022.')
                    MesID = 0;
                    calendario[0].calendario2022.forEach(mes=>{
                        //console.log("mes", mes) To Do ver2: Delete this comment from older programmer.
                        MesID++;
                        console.log('El Mes ID va en: ' + MesID)
                        let ToMutatePorMesInicio = new Date(mes.fechaInicio)
                        let ToMutatePorMesFin = new Date(mes.fechaFin)
                        let ComparafechaVorwerkInicio = TimeZoneMutator(6,ToMutatePorMesInicio);
                        let ComparafechaVorwerkFin = TimeZoneMutator(6,ToMutatePorMesFin);
                        if( CurrentFecha.getTime() > ComparafechaVorwerkInicio.getTime() && CurrentFecha.getTime() < ComparafechaVorwerkFin.getTime() ) {
                           fechaVorwerkActual = [{'inicio': ComparafechaVorwerkInicio}, {'fin': ComparafechaVorwerkFin}]
                            console.log('FechaVorwerkActual Encontrada en 2022: ' + ComparafechaVorwerkInicio + ' / ' + ComparafechaVorwerkFin);
                            CurrentAnio++;
                            CurrentMes = mes.nombreMes;
                            setCalendarioVorwerk(calendario[0].calendario2022)
                            //setCurrentMesNum(MesID)
                            //setCurrentAnioNum(CurrentAnio)
                        }
                    })
            }
            if (CurrentAnio < 2022){
                console.log('No se encontró coincidencias en 2022, verificando con 2023.')
                MesID = 0;
                    calendario[0].calendario2023.forEach(mes=>{
                        //console.log("mes", mes) To Do ver2: Delete this comment from older programmer.
                        MesID++;
                        console.log('El Mes ID va en: ' + MesID)
                        let ToMutatePorMesInicio = new Date(mes.fechaInicio)
                        let ToMutatePorMesFin = new Date(mes.fechaFin)
                        let ComparafechaVorwerkInicio = TimeZoneMutator(6,ToMutatePorMesInicio);
                        let ComparafechaVorwerkFin = TimeZoneMutator(6,ToMutatePorMesFin);
    
                        if( CurrentFecha.getTime() > ComparafechaVorwerkInicio.getTime() && CurrentFecha.getTime() < ComparafechaVorwerkFin.getTime() ) {
                            fechaVorwerkActual = [{'inicio': ComparafechaVorwerkInicio}, {'fin': ComparafechaVorwerkFin}]
                            console.log('FechaVorwerkActual Encontrada en 2023: ' + ComparafechaVorwerkInicio + ' / ' + ComparafechaVorwerkFin);
                            CurrentAnio++;
                            CurrentAnio++;
                            CurrentMes = mes.nombreMes;
                            setCalendarioVorwerk(calendario[0].calendario2023)
                            console.log('BNDDEV El MesNumVar es:'+MesID);
                            MesNumVar = MesID;
                            AnioNumVar = CurrentAnio;
                        }
                    })
            }
            if (CurrentAnio < 2022){
                console.log('No se encontró coincidencias en 2023, verificando con 2024.')
                MesID = 0;
                    calendario[0].calendario2024.forEach(mes=>{
                        //console.log("mes", mes) To Do ver2: Delete this comment from older programmer.
                        MesID++;
                        console.log('El Mes ID va en: ' + MesID)
                        let ToMutatePorMesInicio = new Date(mes.fechaInicio)
                        let ToMutatePorMesFin = new Date(mes.fechaFin)
                        let ComparafechaVorwerkInicio = TimeZoneMutator(6,ToMutatePorMesInicio);
                        let ComparafechaVorwerkFin = TimeZoneMutator(6,ToMutatePorMesFin);
    
                        if( CurrentFecha.getTime() > ComparafechaVorwerkInicio.getTime() && CurrentFecha.getTime() < ComparafechaVorwerkFin.getTime() ) {
                            fechaVorwerkActual = [{'inicio': ComparafechaVorwerkInicio}, {'fin': ComparafechaVorwerkFin}]
                            console.log('FechaVorwerkActual Encontrada en 2023: ' + ComparafechaVorwerkInicio + ' / ' + ComparafechaVorwerkFin);
                            CurrentAnio++;
                            CurrentAnio++;
                            CurrentAnio++;
                            CurrentMes = mes.nombreMes;
                            setCalendarioVorwerk(calendario[0].calendario2024)
                            console.log('BNDDEV El MesNumVar es:'+MesID);
                            MesNumVar = MesID;
                            AnioNumVar = CurrentAnio;
                        }
                    })
            }
            if (CurrentAnio < 2022){
                console.log('No se encontró coincidencias en 2024, verificando con 2025.')
                MesID = 0;
                    calendario[0].calendario2025.forEach(mes=>{
                        //console.log("mes", mes) To Do ver2: Delete this comment from older programmer.
                        MesID++;
                        console.log('El Mes ID va en: ' + MesID)
                        let ToMutatePorMesInicio = new Date(mes.fechaInicio)
                        let ToMutatePorMesFin = new Date(mes.fechaFin)
                        let ComparafechaVorwerkInicio = TimeZoneMutator(6,ToMutatePorMesInicio);
                        let ComparafechaVorwerkFin = TimeZoneMutator(6,ToMutatePorMesFin);
    
                        if( CurrentFecha.getTime() > ComparafechaVorwerkInicio.getTime() && CurrentFecha.getTime() < ComparafechaVorwerkFin.getTime() ) {
                            fechaVorwerkActual = [{'inicio': ComparafechaVorwerkInicio}, {'fin': ComparafechaVorwerkFin}]
                            console.log('FechaVorwerkActual Encontrada en 2023: ' + ComparafechaVorwerkInicio + ' / ' + ComparafechaVorwerkFin);
                            CurrentAnio++;
                            CurrentAnio++;
                            CurrentAnio++;
                            CurrentAnio++;
                            CurrentMes = mes.nombreMes;
                            setCalendarioVorwerk(calendario[0].calendario2025)
                            console.log('BNDDEV El MesNumVar es:'+MesID);
                            MesNumVar = MesID;
                            AnioNumVar = CurrentAnio;
                        }
                    })
            }
            if (CurrentAnio < 2022){
                console.log('No se encontro el año actual. Iniciar proceso de ERROR FATAL (Objetivos.js).')
            }
            
            
                console.log('El mes Vorwerk actual es: '+ CurrentMes + ' del año: ' + CurrentAnio);


                //To Do Ver2: Correct functions are from this line UP------------
                console.log('-------------------------------------------------------------------------------------------------')

            } else {
                console.log("Inicio.js No existe calendario...")
                // Validar correctamente la fecha vorwerk
                if( fecha.getTime() > fechaVorwerkInicio.getTime() && fecha.getTime() < fechaVorwerk.getTime() ) {
                    //console.log("1... esta dentro de la fecha vorwerk")
                    let newFechaSave = new Date(fechaVorwerkInicio.getTime() + (10*24*60*60*1000))
                    mesConsultar = newFechaSave.getMonth()
                } else if( fecha.getTime() < fechaVorwerkInicio.getTime()) {
                    //console.log("2...")
                    // si la fecha de hoy es menor a la fecha inicio de vorwerk
                    mesConsultar = fecha.getMonth()
                } else {
                    // si la fecha de hoy es mayor a la fecha fin de vorwerk
                    if(fecha.getMonth()+1===12){
                        //console.log("3...")
                        fechaVorwerk = utils.calendarioVorwerk[0][0][1].fin;
                        fechaVorwerkInicio = utils.calendarioVorwerk[0][0][0].inicio;
                        let newFechaSave = new Date(fechaVorwerkInicio.getTime() + (10*24*60*60*1000))
                        mesConsultar = (newFechaSave.getMonth())
                    } else {
                        //console.log("4...")
                        fechaVorwerk = utils.calendarioVorwerk[fecha.getMonth()+1][fecha.getMonth()+1][1].fin;
                        fechaVorwerkInicio = utils.calendarioVorwerk[fecha.getMonth()+1][(fecha.getMonth()+1)][0].inicio;
                        let newFechaSave = new Date(fechaVorwerkInicio.getTime() + (10*24*60*60*1000))
                        mesConsultar = (newFechaSave.getMonth())
                    }
                }
                //console.log("mesConsultar ", mesConsultar)
                fechaVorwerkActual = utils.calendarioVorwerk[mesConsultar][mesConsultar]
            }

            console.log("fechaVorwerkActual para Objetivos.js --> ", fechaVorwerkActual)
            //setFechaVorwerkDate(fechaVorwerkActual)

            //console.log("1. mesConsultar --> ", mesConsultar)
            //console.log("2. ---> inicio: "+ fechaVorwerkInicio + ' fin: ' + fechaVorwerk)
            console.log('-----> User: '+currentUser);
            console.log('-----> IDU: '+currentUser.idu);
            console.log('-----> MES: '+MesNumVar);
            console.log('-----> Año: '+AnioNumVar);
            //BND-DEV-2025: Change year variable to auto.
            AuthService.consult(`{
                goalsIduMonthYear(iduUser:"${currentUser.idu}" month: ${MesNumVar}
                year: ${'2025'} ){ id iduUser prospectos experiencias ventas budgets month year created_date}
                }`
            )
            .then(result=>{
                let goals = result.data.data.goalsIduMonthYear;
                console.log('-------> BNDDEV: Goals Detail LINE:198 - VALUE:'+goals+'<------------');
                console.log(goals);
                if( goals !== null && goals.length > 0 ){
                    setGoalsBD(goals);
                    console.log('BNDDEV: Goals found:'+goals);
                    setDatos({
                        prospectos: goals[0].prospectos,
                        experiencias: goals[0].experiencias,
                        ventas: goals[0].ventas,
                        budgets: goals[0].budgets
                    });
                    /* 
                    Vamos a ver la fecha de creacion de los objetivos, si ya pasaron 48 hrs de su creacion no se mostraran botones
                    y que la fecha de creacion sea mayor o igual a la fecha inicio del mes vorwerk
                    */

                   let date = new Date (goals[0].created_date);
                   //Number of hours to compare is 6 (for the timezone) -48 for time limit.
                   let CreatedMXDate = (date.setTime(date.getTime() + 42 * 60 * 60 * 1000));
                    console.log('Fecha de comparación: '+goals[0].created_date);
                    //const CreatedMXTime = TimeZoneMutator(6,goals[0].created_date);
                    console.log('Fecha de comparación HORA MX: '+CreatedMXDate);
                    let datesecond = new Date();
                    let FechaNOW = (datesecond.setTime(datesecond.getTime() + -6 * 60 * 60 * 1000));
                    console.log('Fecha actual del sistema: '+FechaNOW);
                    if (FechaNOW < CreatedMXDate){
                        console.log('Fecha dentro de rango editable.');
                    } else {
                        setMostrarBotones(false);
                        console.log('Fecha fuera de rango editable.');
                    }

                    //console.log(fechaCrcn)
                    //console.log("fechaVorwerkInicio", fechaVorwerkInicio)
                    //console.log("fechaHoy: "+ new Date().getTime()+" -> fechaCrcn + 48 hrs " + (fechaCrcn.getTime()+(48*60*60*1000)) );
                    
                    /*
                    if(new Date().getTime()>(fechaCrcn.getTime()+(48*60*60*1000)) &&  
                        fechaCrcn.getTime()>=fechaVorwerkInicio.getTime()
                    ) console.log('Fecha dentro de rango editable.');
                    {
                        setMostrarBotones(false);
                        console.log('Fecha fuera de rango editable.');
                    }
                    */
                }
            })
            .catch(error=>{
                console.log(error);
            });
            
        })
        .catch(error=>{
            console.log(error);
        })

    }, []);

    const saveMyObjectives = () => {
        let fecha = new Date()
        // Esta consulta debe ser en base al mes Vorwerk
        let fechaVorwerk = utils.calendarioVorwerk[fecha.getMonth()][fecha.getMonth()][1].fin;
        let fechaVorwerkInicio = utils.calendarioVorwerk[fecha.getMonth()][fecha.getMonth()][0].inicio;
        let mesSave = (fechaVorwerkInicio.getMonth()+1)
        console.log('BNDDEV: prov mesSave var use:'+mesSave);
        //BND-DEV-2025: replace date functions to automatic months and years.
        //let yearConsult = fecha.getFullYear()
        let yearConsult = 2025
        //console.log("mesSave ", mesSave)
        if(calendarioVorwerk && calendarioVorwerk.length>0){
            calendarioVorwerk.forEach(mes=>{
                //console.log("mes", mes)
                fechaVorwerkInicio = new Date(mes.fechaInicio)
                fechaVorwerk = new Date(mes.fechaFin) //
                if( fecha.getTime() > fechaVorwerkInicio.getTime() && fecha.getTime() < fechaVorwerk.getTime() ) {
                    let newFechaSave = new Date(fechaVorwerkInicio.getTime() + (10*24*60*60*1000))
                    mesSave = newFechaSave.getMonth()+1
                    yearConsult = newFechaSave.getFullYear()
                }
            })
        } else {
            if( fecha.getTime() > fechaVorwerkInicio.getTime() && fecha.getTime() < fechaVorwerk.getTime() ) {
                let newFechaSave = new Date(fechaVorwerkInicio.getTime() + (10*24*60*60*1000))
                mesSave = newFechaSave.getMonth()+1
            } else if( fecha.getTime() < fechaVorwerkInicio.getTime()) {
                mesSave = fecha.getMonth()
            } else {
                // si la fecha de hoy es mayor a la fecha fin de vorwerk
                if(fecha.getMonth()+1===12){
                    fechaVorwerk = utils.calendarioVorwerk[0][0][1].fin;
                    fechaVorwerkInicio = utils.calendarioVorwerk[0][0][0].inicio;
                    let newFechaSave = new Date(fechaVorwerkInicio.getTime() + (10*24*60*60*1000))
                    yearConsult = yearConsult+1;
                    mesSave = (newFechaSave.getMonth()+1)
                } else {
                    fechaVorwerk = utils.calendarioVorwerk[fecha.getMonth()+1][fecha.getMonth()+1][1].fin;
                    fechaVorwerkInicio = utils.calendarioVorwerk[fecha.getMonth()+1][(fecha.getMonth()+1).toString()][0].inicio;
                    let newFechaSave = new Date(fechaVorwerkInicio.getTime() + (10*24*60*60*1000))
                    mesSave = (newFechaSave.getMonth()+1)
                }
            }
        }


        let operation = 'addGoals(';

        if(goalsBD.length>0){
            operation = 'updateGoals(id: "' + goalsBD[0].id + '"';
        }
        console.log('------->goals Check<---------');
        console.log('BDNDEV: Goals to save YEAR:'+yearConsult+' MONTH:');
        console.log(goalsBD);
        let gqlGoal = `mutation{
              ${operation}
                iduUser: "${currentUser.idu}"
                prospectos: ${parseInt(datos.prospectos)}
                experiencias: ${parseInt(datos.experiencias)}
                ventas: ${parseInt(datos.ventas)}
                budgets: ${parseInt(datos.budgets)}
                month: ${'3'}
                year: ${yearConsult}
              ){
                id iduUser prospectos experiencias ventas budgets month year created_date
              }
            }`;
        //console.log(gqlGoal);
        
        AuthService.consult(gqlGoal)
        .then(result=>{
            let addGoals = goalsBD.length>0 ? result.data.data.updateGoals: result.data.data.addGoals;
            //console.log(addGoals);
            setGoalsBD([addGoals]);
            setEditar(false);
            alerts("Se guardaron sus objetivos del mes.", false);
            localStorage.setItem('titleToolbar', '');
            history.push('/');
            window.location.reload();
        })
        .catch(error=>{
            console.log(error);
            alerts("Ocurrio un error al guardar la información.", true);
        });
    };

    const editMyObjectives = (ev) => {
        console.log("editar... ")
        setMostrarBotones(false)
        setEditar(true)
    }

    const handleInputChange = (event) => {
        setDatos({
            ...datos,
            [event.target.name] : event.target.value
        })
    };

    const alerts = (msg, error) =>{
        toast(msg, {
            className: css({
                background: error ? "#E6332A":"#00AC46",
                color: "white"
            }),
            progressClassName: css({
                background: error ? "#00AC46":"#FFF"
            })
        });
    };

    return (
        <div>
            <h3>¿Qué quiero lograr este mes?</h3>
            {
                currentUser.rol[0].value===5 ?
                <div className="row">
                    <div className="col l3" style={{textAlign: "right"}}>
                        <p>Budgets:</p>
                    </div>
                    <div className="col l3">
                        {
                            goalsBD.length > 0 && !editar  ?
                                <input className="form-control" type="number" value={datos.budgets} readOnly/>
                                :
                                <input className="form-control" type="number" placeholder="Número de budgets"
                                       value={datos.budgets} name="budgets" onChange={handleInputChange}/>
                        }
                    </div>
                </div>
                    :
                <>
                    <div className="row">
                        <div className="col l3" style={{textAlign: "right"}}>
                            <p>Ventas:</p>
                        </div>
                        <div className="col l3">
                            {
                                goalsBD.length > 0 && !editar  ?
                                    <input className="form-control" type="number" value={datos.ventas} readOnly/>
                                    :
                                    <input className="form-control" type="number" placeholder="Número de ventas"
                                           value={datos.ventas} name="ventas" onChange={handleInputChange}/>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col l3" style={{textAlign: "right"}}>
                            <p>Demos:</p>
                        </div>
                        <div className="col l3">
                            {
                                goalsBD.length > 0 && !editar  ?
                                    <input className="form-control" type="number" value={datos.experiencias} readOnly/>
                                    :
                                    <input className="form-control" type="number" placeholder="Número de experiencias"
                                           value={datos.experiencias} name="experiencias" onChange={handleInputChange}/>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col l3" style={{textAlign: "right"}}>
                            <p>Prospectos:</p>
                        </div>
                        <div className="col l3">
                            {
                                goalsBD.length > 0 && !editar ?
                                    <input className="form-control" type="number" value={datos.prospectos} readOnly/>
                                    :
                                    <input className="form-control" type="number" placeholder="Número de prospectos"
                                           value={datos.prospectos} name="prospectos" onChange={handleInputChange}/>
                            }
                        </div>
                    </div>
                </>
            }


            {
                mostrarBotones &&
                <div className="row" style={{textAlign: "center"}}>
                    {
                        goalsBD.length!==0 &&
                        <Button onClick={(ev)=>editMyObjectives(ev)}
                        style={{backgroundColor: "#00AC46",
                        borderRadius:"10px", color: "white"}}>
                            <img src="/assets/img/icons/edit_white_23.png" className="iconEdit" alt="Editar"/>
                            <span style={{marginRight: "5px"}}>Editar</span>
                        </Button>
                    }
                    {
                        (editar || goalsBD.length===0) &&
                        <Button onClick={(ev)=>saveMyObjectives(ev)}
                                style={{backgroundColor: "#00AC46",
                                    borderRadius:"10px", color: "white"}}>
                            <img src="/assets/img/icons/guardar_white_23.png" className="iconGuardar" alt="Guardar"/>
                            <span style={{marginRight: "5px"}}>Guardar</span>
                        </Button>
                    }
                </div>
            }
            {
                !mostrarBotones &&
                <div className="row" style={{textAlign: "center"}}>
                    {
                        (editar || goalsBD.length===0) &&
                        <Button onClick={(ev)=>saveMyObjectives(ev)}
                                style={{backgroundColor: "#00AC46",
                                    borderRadius:"10px", color: "white"}}>
                            <img src="/assets/img/icons/guardar_white_23.png" className="iconGuardar" alt="Guardar"/>
                            <span style={{marginRight: "5px"}}>Guardar</span>
                        </Button>
                    }
                </div>
            }
        </div>
    );
};

export default Objetivos;